.at {
  color: #ff7f00;
  text-decoration: none;
  font-weight: 500;
}

.carousel-item {
  text-align: center;
}

.carousel-indicators button{
  width: 10px !important;
  height: 10px !important;
  opacity: .4;
  border-radius: 100%;
  margin: 0px 4px;
  cursor: pointer; 
  background-color: black !important;
}
.carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-control-prev-icon {
  filter: invert(1) grayscale(100);
}